import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function PositionPage(props) {
	let history = useHistory();
	const [isBusy, setBusy] = useState(true);
	const [apiData, setApiData] = useState('');
	let id = apiData ? apiData._id : 'there is no ID';

	//! component specific properties
	const [URL, setURL] = useState('');
	const [nameEn, setnameEn] = useState('');
	const [nameHy, setnameHy] = useState('');

	let modelSendToServer = { URL, nameEn, nameHy };

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.routes.live, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.routes.live, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.routes.live, id);
		history.goBack();
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				const rowData = await API.get(config.routes.live);
				const data = rowData.data[0];
				if (data) {
					setApiData(data);
					setURL(data.URL);
					setnameEn(data.nameEn);
					setnameHy(data.nameHy);
				}
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (!isBusy) {
		return (
			<SinglePage
				inputs={[
					{
						value: URL,
						setter: setURL,
						type: 'textfield',
						label: 'URL',
					},
					{
						value: nameEn,
						setter: setnameEn,
						type: 'textfield',
						label: 'Name',
					},
					{
						value: nameHy,
						setter: setnameHy,
						type: 'textfield',
						label: 'Անուն',
					},
				]}
				isBusy={isBusy}
				setBusy={setBusy}
				apiData={apiData}
				route={config.routes.live}
				CREATE={createItem}
				UPDATE={updateItem}
				REMOVE={removeItem}
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
