import React, { useEffect } from 'react';

export const useStateWithLocalStorage = (localStorageKey) => {
	const [value, setValue] = React.useState(
		localStorage.getItem(localStorageKey) || ''
	);

	useEffect(() => {
		localStorage.setItem(localStorageKey, value);
	}, [value, localStorageKey]);

	return [value, setValue];
};
