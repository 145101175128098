import React, { Fragment, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
//! import modules

import Input from './modules/input';
import { FilesComponent } from './modules/filesComponent';
import { SliderComponent } from './modules/sliderComponent';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

// !Date stuff
import 'date-fns';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// !Select option stuff
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 10,
	},
	button: {
		margin: theme.spacing(1),
	},
	singlePageContent: {
		marginBottom: 50,
	},
	singlePageBtnGroup: {
		position: 'fixed',
		width: '100%',
		bottom: 0,
		height: 50,
		zIndex: 999,
		background: '#fafafa',
	},
}));

export default function SinglePage(props) {
	const {
		inputs,
		filesComponent,
		sliderComponent,
		UPDATE,
		REMOVE,
		isBusy,
		CREATE,
	} = props;

	let history = useHistory();
	let editMode;
	let pageTitle;
	const classes = useStyles();

	if (useParams().id === 'add') {
		editMode = false;
		if (history.location.pathname.split('/')[1] === 'aboutUs/collaborators') {
			pageTitle = 'Add Collaborator';
		} else if (history.location.pathname.split('/')[1] === 'aboutUs/partners') {
			pageTitle = 'Add Partner';
		} else if (history.location.pathname.split('/')[1] === 'aboutUs/team') {
			pageTitle = 'Add Team Member';
		} else if (history.location.pathname.split('/')[1] === 'program') {
			pageTitle = 'Add program';
		} else {
			pageTitle = 'Add new';
		}
	} else {
		editMode = true;
		pageTitle = 'Editing';
	}

	useEffect(() => {}, [isBusy]);

	// ! MAIN BUTTONS --------------SAVE------REMOOVE------CANCEL
	const saveItem = () => {
		if (editMode) UPDATE();
		else CREATE();
	};
	const removeItem = () => {
		REMOVE();
	};
	const cancelBack = () => {
		history.goBack();
	};

	return (
		<Fragment>
			<div className={classes.singlePageContent}>
				<Typography align='center' variant='h4' gutterBottom={true}>
					{pageTitle}{' '}
				</Typography>

				<form className={classes.root}>
					{filesComponent &&
						filesComponent.map((component, index) => {
							return (
								<FilesComponent
									file={component.file}
									filesUploadLimit={component.filesUploadLimit}
									acceptedFiles={component.acceptedFiles}
									uploadFile={component.uploadFile}
									removeFile={component.removeFile}
									lang={component.lang}
									buttonAddText={component.buttonAddText}
									buttonOpenText={component.buttonOpenText}
									buttonRemoveText={component.buttonRemoveText}
									dropzoneText={component.dropzoneText}
									key={index}
								/>
							);
						})}
				</form>

				<form className={classes.root} onSubmit={saveItem}>
					{inputs
						? inputs.map((input, index) => {
								return (
									<Input
										label={input.label}
										value={input.value}
										setter={input.setter}
										type={input.type}
										optionListValue={input.optionListValue}
										key={index}
									/>
								);
						  })
						: null}
				</form>

				<form className={classes.root}>
					{sliderComponent &&
						sliderComponent.map((component, index) => {
							return (
								<SliderComponent
									files={component.files}
									name={component.name}
									filesUploadLimit={component.filesUploadLimit}
									acceptedFiles={component.acceptedFiles}
									buttonAddText={component.buttonAddText}
									uploadFile={component.uploadFile}
									removeFile={component.removeFile}
									dropzoneText={component.dropzoneText}
									lang={component.lang}
									key={index}
								/>
							);
						})}
				</form>
			</div>

			<div className={classes.singlePageBtnGroup}>
				<Divider />
				<Button
					variant='contained'
					color='primary'
					className={classes.button}
					startIcon={<SaveIcon />}
					onClick={saveItem}
				>
					Save
				</Button>

				{editMode ? (
					<Button
						variant='contained'
						color='secondary'
						className={classes.button}
						startIcon={<DeleteIcon />}
						onClick={removeItem}
					>
						Remove
					</Button>
				) : (
					<Button
						variant='contained'
						color='secondary'
						className={classes.button}
						startIcon={<CancelIcon />}
						onClick={cancelBack}
					>
						Cancel
					</Button>
				)}
			</div>
		</Fragment>
	);
}
