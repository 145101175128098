import React, { useEffect, useState } from 'react';
import { ListPage } from '../../ListPage';
import config from '../../../config';
import * as API from '../../../helpers/api';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function TeamList() {
	let [apiData, setApiData] = useState([]);
	let [columns, setColumns] = useState([]);
	let [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.aboutUs.team);
			setApiData(rawData.data);

			setColumns([
				{ title: 'Name', field: 'nameEn' },
				{ title: 'Անուն', field: 'nameHy' },
			]);
			setBusy(false);
		})();
	}, [isBusy]);

	if (!isBusy) {
		return (
			<ListPage
				apiData={apiData}
				title='Team'
				columns={columns}
				CLICK={true}
				tooltip='Add Team Member'
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
