import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from '../helpers/auth';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Copyright() {
	return (
		<Typography variant='body2' color='textSecondary' align='center'>
			{'Copyright © '}
			<Link color='inherit' href='https://perf.am/'>
				perf
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function Login() {
	const classes = useStyles();
	let [alert, setAlert] = useState(false);
	let [username, setUsername] = useState('');
	let [password, setPassword] = useState('');
	const { loggedIn, login } = useAuth();

	function handleUsernameChange(event) {
		setUsername(event.target.value);
	}

	function handlePasswordChange(event) {
		setPassword(event.target.value);
	}

	function handleSubmit(event) {
		event.preventDefault();
		login(username, password, 'admin');
		if (!loggedIn) setTimeout(() => setAlert(true), 500);
		setTimeout(() => setAlert(false), 3500);
	}

	if (loggedIn) {
		return <Redirect to='/' />;
	} else {
		return (
			<React.Fragment>
				<Container component='main' maxWidth='xs'>
					<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>

						<Typography component='h1' variant='h5'>
							Log in to Perf admin panel
						</Typography>
						<form className={classes.form} noValidate>
							<TextField
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='login'
								label='Login'
								name='login'
								value={username}
								onChange={handleUsernameChange}
								autoComplete='login'
								autoFocus
							/>
							<TextField
								variant='outlined'
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type='password'
								id='password'
								value={password}
								onChange={handlePasswordChange}
								autoComplete='current-password'
							/>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								onClick={handleSubmit}
								className={classes.submit}
							>
								Log in
							</Button>
						</form>
					</div>
					<Box mt={8}>
						<Copyright />
					</Box>
					<Box mt={8}>
						{alert ? (
							<Alert variant='filled' severity='error'>
								No user with the provided credentials found
							</Alert>
						) : (
							<div></div>
						)}
					</Box>
				</Container>
			</React.Fragment>
		);
	}
}
