import React, { useState, useEffect } from 'react';
import SinglePage from '../../SinglePage';
import { useHistory } from 'react-router-dom';
import * as API from '../../../helpers/api';
import config from '../../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function SelfsPage(props) {
	let history = useHistory();
	const [isBusy, setBusy] = useState(true);
	const [apiData, setApiData] = useState('');
	let id = apiData ? apiData._id : '';

	//! component specific properties
	const [descEn, setDescEn] = useState('');
	const [descHy, setDescHy] = useState('');
	const [image, setImage] = useState(undefined);

	let modelSendToServer = {
		descEn: descEn,
		descHy: descHy,
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.routes.aboutUs.self, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.routes.aboutUs.self, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.routes.aboutUs.self, id);
		history.goBack();
	}
	async function removeFile(id) {
		setBusy(true);
		setImage('');
		await API.remove(config.routes.upload, id);
	}
	async function uploadFile(files, lang) {
		setBusy(true);
		if (id === 'add') {
			const rowData = await API.post(
				config.routes.aboutUs.collaborators,
				modelSendToServer
			);
			id = rowData.data._id;
			await history.push(`${id}`);
			await API.upload(id, files, lang);
		} else {
			await API.upload(id, files, lang);
		}
		setBusy(false);
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				const rowData = await API.get(config.routes.aboutUs.self);
				const data = rowData.data[0];
				if (data) {
					setApiData(data);
					setDescEn(data.descEn);
					setDescHy(data.descHy);
				}
				if (id !== '') {
					const rowImages = await API.get(config.routes.upload, {
						obj_id: id,
						type: { $regex: '.*image.*' },
					});
					setImage(rowImages.data[0]);
				}
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (!isBusy) {
		return (
			<SinglePage
				inputs={[
					{
						value: descEn,
						setter: setDescEn,
						type: 'textarea',
						label: 'description',
					},
					{
						value: descHy,
						setter: setDescHy,
						type: 'textarea',
						label: 'նկարագրություն',
					},
				]}
				filesComponent={[
					{
						name: 'image',
						file: image,
						filesUploadLimit: 1,
						acceptedFiles: ['image/*'],
						uploadFile: uploadFile,
						removeFile: removeFile,
						buttonAddText: 'Add',
						buttonOpenText: 'Open',
						buttonRemoveText: 'Romove',
						dropzoneText:
							'The file is missing click on the add button to upload it',
					},
				]}
				isBusy={isBusy}
				setBusy={setBusy}
				apiData={apiData}
				route={config.routes.aboutUs.self}
				CREATE={createItem}
				UPDATE={updateItem}
				REMOVE={removeItem}
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
