import axios from 'axios';
import { cookies } from './cookies';
import config from '../config';

const API = axios.create({
	baseURL: `${config.routes.API_URL}/v1/`,
});

export function get(route, query) {
	return new Promise((resolve, reject) => {
		if (query) {
			API.get(`${route}?q=${JSON.stringify(query)}`, {
				headers: {
					authorization: cookies.get('authorization'),
				},
			})
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		} else {
			API.get(`${route}`, {
				headers: {
					authorization: cookies.get('authorization'),
				},
			})
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		}
	});
}
export function getById(route, id) {
	return new Promise((resolve, reject) => {
		API.get(`${route}/${id}`, {
			headers: {
				authorization: cookies.get('authorization'),
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function post(route, data) {
	return new Promise((resolve, reject) => {
		API.post(route, data, {
			headers: {
				authorization: cookies.get('authorization'),
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function update(route, id, data) {
	return new Promise((resolve, reject) => {
		API.put(`${route}/${id}`, data, {
			headers: {
				authorization: cookies.get('authorization'),
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export function remove(route, id) {
	return new Promise((resolve, reject) => {
		API.delete(`${route}/${id}`, {
			headers: {
				authorization: cookies.get('authorization'),
			},
		})
			.then((res) => resolve(res))
			.catch((error) => reject(error));
	});
}
export const upload = (id, files, lang) => {
	if (id) {
		files.forEach((file, index) => {
			const formData = new FormData();
			formData.append('obj_id', id);
			formData.append('file', file);
			formData.append('lang', lang);
			API.post(config.routes.upload, formData, {
				headers: {
					authorization: cookies.get('authorization'),
				},
			});
		});
	} else {
		files.forEach((file, index) => {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('lang', lang);
			API.post(config.routes.upload, formData, {
				headers: {
					authorization: cookies.get('authorization'),
				},
			});
		});
	}
};

export default API;
