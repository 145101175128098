import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function ProgramPage(props) {
	let history = useHistory();
	let id = useParams().id;
	const [isBusy, setBusy] = useState(true);
	const [apiData, setApiData] = useState('');

	//! component specific properties
	const [nameEn, setNameEn] = useState('');
	const [nameHy, setNameHy] = useState('');
	const [descEn, setDescEn] = useState('');
	const [descHy, setDescHy] = useState('');
	const [date, setdate] = useState('');
	const [image, setImage] = useState(undefined);

	let modelSendToServer = {
		nameEn,
		nameHy,
		descEn,
		descHy,
		date,
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.routes.program, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.routes.program, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.routes.program, id);
		history.goBack();
	}
	async function removeFile(id) {
		setBusy(true);
		setImage('');
		await API.remove(config.routes.upload, id);
	}
	async function uploadFile(files, lang) {
		setBusy(true);
		if (id === 'add') {
			const rowData = await API.post(config.routes.program, modelSendToServer);
			id = rowData.data._id;
			await history.push(`${id}`);
			await API.upload(id, files, lang);
		} else {
			await API.upload(id, files, lang);
		}
		setBusy(false);
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				const rowData = await API.getById(config.routes.program, id);
				const data = rowData.data;

				if (data) {
					setApiData(data);
					setNameEn(data.nameEn ? data.nameEn : null);
					setNameHy(data.nameHy ? data.nameHy : null);
					setDescEn(data.descEn ? data.descEn : null);
					setDescHy(data.descHy ? data.descHy : null);
					setdate(data.date ? data.date : null);
				}

				const rowImages = await API.get(config.routes.upload, {
					obj_id: id,
					type: { $regex: '.*image.*' },
				});
				setImage(rowImages.data[0]);
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (!isBusy) {
		return (
			<SinglePage
				inputs={[
					{
						value: nameEn,
						setter: setNameEn,
						type: 'textfield',
						label: 'Name',
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: 'textfield',
						label: 'ԱՆուն',
					},
					{
						value: date,
						setter: setdate,
						type: 'datePicker',
						label: 'Select Date',
					},
					{
						value: descEn,
						setter: setDescEn,
						type: 'textarea',
						label: 'description',
					},
					{
						value: descHy,
						setter: setDescHy,
						type: 'textarea',
						label: 'նկարագրություն',
					},
				]}
				filesComponent={[
					{
						name: 'image',
						file: image,
						filesUploadLimit: 1,
						acceptedFiles: ['image/*'],
						uploadFile: uploadFile,
						removeFile: removeFile,
						buttonAddText: 'Add',
						buttonOpenText: 'Open',
						buttonRemoveText: 'Romove',
						dropzoneText:
							'The file is missing click on the add button to upload it',
					},
				]}
				isBusy={isBusy}
				setBusy={setBusy}
				apiData={apiData}
				route={config.routes.program}
				CREATE={createItem}
				UPDATE={updateItem}
				REMOVE={removeItem}
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
