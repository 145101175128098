const config = {
    routes: {
        aboutUs: {
            self: 'aboutUs/self',
            collaborators: 'aboutUs/collaborators',
            partners: 'aboutUs/partners',
            team: 'aboutUs/team',
        },
        program: 'program',
        live: 'live',
        position: 'position',
        upload: 'uploads',
        API_URL: process.env.REACT_APP_API_URL
    }
}

export default config