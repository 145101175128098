import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { Editor } from '@tinymce/tinymce-react';
import Typography from '@material-ui/core/Typography';

// !Select option stuff
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// !Date stuff
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 10,
	},
}));

const Input = (props) => {
	const classes = useStyles();
	const handleChange = (e) => {
		props.setter(e.target && e.target.value);
	};

	const handleTextFieldChange = (data) => {
		props.setter(data);
	};

	const handleDateCHange = (date) => {
		props.setter(date);
	};

	if (props.type === 'textfield') {
		return (
			<TextField
				id={Date.now().toString()}
				label={props.label}
				variant='outlined'
				value={props.value}
				onChange={handleChange}
				className={classes.root}
			/>
		);
	} else if (props.type === 'textarea') {
		return (
			<Fragment>
				<Typography
					variant='h4'
					component='h2'
					style={{ textAlign: 'center', margin: 20 }}
				>
					{props.label}
				</Typography>
				<Editor
					className={classes.root}
					initialValue={props.value}
					init={{
						height: '75vh',
						width: '100%',
						statusbar: false,
						menubar: 'file edit insert view format table',
						plugins: [
							'charmap advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount',
						],
						formatpainter_blacklisted_formats:
							'link,address,removeformat,formatpainter_removeformat',
						toolbar:
							// eslint-disable-next-line
							'charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
					}}
					value={props.value}
					onEditorChange={handleTextFieldChange}
				/>
			</Fragment>
		);
	} else if (props.type === 'optionList') {
		return (
			<FormControl variant='outlined' className={classes.root}>
				<InputLabel id='Category'>{props.label}</InputLabel>
				<Select value={props.value} onChange={handleChange} label={props.label}>
					{props.optionListValue &&
						props.optionListValue.map((option, index) => (
							<MenuItem value={option} key={index}>
								{option.nameRu
									? option.nameRu
									: option.nameEn
									? option.nameEn
									: option.nameHy
									? option.nameHy
									: 'нету имени'}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		);
	} else if (props.type === 'datePicker') {
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container justify='space-around'>
					<KeyboardDatePicker
						margin='normal'
						id='date-picker-dialog'
						format='MM/dd/yyyy'
						value={props.value}
						onChange={handleDateCHange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</Grid>
			</MuiPickersUtilsProvider>
		);
	} else {
		return <h1>There is no content to show in input</h1>;
	}
};

export default Input;
