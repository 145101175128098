import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paginationNav: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	paginationUl: {
		display: 'flex',
		paddingLeft: 0,
		listStyle: 'none',
		borderRadius: '.25rem',
	},
	pageItem: {
		padding: '3px 0',
		display: 'inline-block',
		cursor: 'pointer',
	},
	pageLink: {
		position: 'relative',
		display: 'block',
		padding: '.5rem .75rem',
		marginLeft: '-1px',
		lineHeight: 1.25,
		color: '#007bff',
		backgroundColor: '#fff',
		border: '1px solid #dee2e6',
	},
	selected: {
		backgroundColor: '#dee2e6',
	},
}));
const Pagination = ({ dataPerPage, totalCount, paginate, currentPage }) => {
	const pageNumbers = [];
	let classes = useStyles();

	for (let i = 1; i <= Math.ceil(totalCount / dataPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<nav className={classes.paginationNav}>
			<ul className={classes.paginationUl}>
				{pageNumbers.map((number) => {
					return (
						<li key={number} className={classes.pageItem}>
							<span
								onClick={() => paginate(number)}
								className={
									currentPage === number
										? `${classes.pageLink} ${classes.selected}`
										: classes.pageLink
								}
							>
								{number}
							</span>
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export default Pagination;
