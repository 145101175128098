import React from 'react';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GroupIcon from '@material-ui/icons/Group';
import PolymerIcon from '@material-ui/icons/Polymer';
import SmsIcon from '@material-ui/icons/Sms';
import StoreIcon from '@material-ui/icons/Store';
import TuneIcon from '@material-ui/icons/Tune';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

export const navigationPrimary = [
	{
		name: 'About Us',
		icon: <PolymerIcon />,
		collapse: [
			{
				name: 'Self',
				route: 'aboutUs/self',
				icon: <GroupIcon />,
			},
			{
				name: 'Collaborators',
				route: 'aboutUs/collaborators',
				icon: <BusinessCenterIcon />,
			},
			{
				name: 'Partners',
				route: 'aboutUs/partners',
				icon: <StoreIcon />,
			},
			{
				name: 'Team',
				route: 'aboutUs/team',
				icon: <GroupIcon />,
			},
		],
	},
	{ name: 'program', route: 'program', icon: <SmsIcon /> },
	{
		name: 'live',
		route: 'live',
		icon: <BusinessCenterIcon />,
	},
];
export const navigationSecondary = [
	{ name: 'positions', route: 'positions', icon: <TuneIcon /> },
	{ name: 'media', route: 'media', icon: <ImageSearchIcon /> },
];
