import React, { useState, Fragment } from 'react';
import config from '../../config';
import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import AddIcon from '@material-ui/icons/Add';

// ! Card stuff
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

//! button group
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	image: {
		margin: theme.spacing(1),
	},
	media: {
		height: 300,
		padding: theme.spacing(0.5),
		textAlign: 'center',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export function FilesComponent(props) {
	const [openDialog, setDialog] = useState(false);
	const classes = useStyles();

	var disabled = false;
	var fileId = '';
	var fileLang = '';
	if (props.file === undefined) {
		disabled = true;
	} else if (props.file) {
		fileId = props.file._id;
		fileLang = props.lang;
	}

	const handleClose = () => {
		setDialog(false);
	};

	const handleSave = (files) => {
		setDialog(false);
		props.uploadFile(files, props.lang);
	};

	const handleOpen = () => {
		setDialog(true);
	};

	const openInNewTab = (e) => {
		let win = window.open(
			`${config.routes.API_URL}/${props.file.path}`,
			'_blank'
		);
		win.focus();
	};

	const removeFile = (e) => {
		props.removeFile(e.currentTarget.id);
	};

	return (
		<Fragment>
			<Card className={classes.image}>
				<CardContent>
					{!disabled ? (
						props.file.type === 'application/pdf' ? (
							<embed
								className={classes.media}
								type='application/pdf'
								width='100%'
								src={`${config.routes.API_URL}/${props.file.path}`}
							/>
						) : (
							<CardMedia className={classes.media}>
								<img
									className={classes.media}
									src={`${config.routes.API_URL}/${props.file.path}`}
									alt='card'
								/>
							</CardMedia>
						)
					) : (
						<CardMedia className={classes.media}>
							<Typography variant='h5' gutterBottom>
								{props.dropzoneText}
							</Typography>
						</CardMedia>
					)}
				</CardContent>
				<CardActions>
					<DropzoneDialog
						open={openDialog}
						onSave={handleSave}
						acceptedFiles={props.acceptedFiles}
						showPreviews={true}
						maxFileSize={5000000}
						onClose={handleClose}
						filesLimit={props.filesUploadLimit}
						dropzoneText={props.dropzoneText}
					/>
					<div className={classes.buttonGroup}>
						<ButtonGroup
							variant='contained'
							color='primary'
							aria-label='contained primary button group'
						>
							<Button
								variant='contained'
								color='primary'
								startIcon={<AddIcon />}
								disabled={!disabled}
								id={fileId}
								lang={fileLang}
								onClick={handleOpen}
							>
								{props.buttonAddText}
							</Button>

							<Button
								variant='contained'
								color='default'
								endIcon={<LaunchIcon />}
								disabled={props.slider || disabled}
								display={props.slider ? 'none' : 'inline'}
								onClick={openInNewTab}
							>
								{props.buttonOpenText}
							</Button>

							<Button
								variant='contained'
								color='secondary'
								startIcon={<DeleteIcon />}
								disabled={disabled}
								id={fileId}
								onClick={removeFile}
							>
								{props.buttonRemoveText}
							</Button>
						</ButtonGroup>
					</div>
				</CardActions>
			</Card>
		</Fragment>
	);
}
