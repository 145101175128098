import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function PositionPage(props) {
	let history = useHistory();
	let id = useParams().id;
	const [isBusy, setBusy] = useState(true);
	const [apiData, setApiData] = useState('');

	//! component specific properties
	const [nameEn, setNameEn] = useState('');
	const [nameHy, setNameHy] = useState('');

	let modelSendToServer = {
		nameEn: nameEn,
		nameHy: nameHy,
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.routes.position, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.routes.position, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.routes.position, id);
		history.goBack();
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				const rowData = await API.getById(config.routes.position, id);
				const data = rowData.data;
				if (data) {
					setApiData(data);
					setNameEn(data.nameEn);
					setNameHy(data.nameHy);
				}
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (!isBusy) {
		return (
			<SinglePage
				inputs={[
					{
						value: nameEn,
						setter: setNameEn,
						type: 'textfield',
						label: 'Name',
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: 'textfield',
						label: 'ԱՆուն',
					},
				]}
				isBusy={isBusy}
				setBusy={setBusy}
				apiData={apiData}
				route={config.routes.position}
				CREATE={createItem}
				UPDATE={updateItem}
				REMOVE={removeItem}
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
