import React, { useEffect, useState } from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import * as API from '../../helpers/api';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function ProgramList() {
	let [apiData, setApiData] = useState([]);
	let [columns, setColumns] = useState([]);
	let [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.program);
			setApiData(rawData.data);

			setColumns([
				{ title: 'Name', field: 'nameEn' },
				{ title: 'Անուն', field: 'nameHy' },
				{
					title: 'Date',
					field: 'date',
					render: (rowData) => {
						let date = Date.parse(rowData.date);
						return new Date(date).toLocaleDateString();
					},
				},
			]);
			setBusy(false);
		})();
	}, [isBusy]);

	if (!isBusy) {
		return (
			<ListPage
				apiData={apiData}
				title='Program'
				columns={columns}
				CLICK={true}
				tooltip='Add Program'
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
