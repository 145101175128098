import React, { useState } from 'react';
import './App.css';

// Helpers
import history from './helpers/history';
import { cookies, setCookies } from './helpers/cookies';
import { useStateWithLocalStorage } from './helpers/storage';
import { isLoggedIn } from './helpers/auth';
import API from './helpers/api';
import { Language } from './helpers/language';

// Components

// global components
import Login from './components/login';
import Home from './components/home';
import Menu from './components/menu/menu';
import AboutUsSelfPage from './components/aboutUs/self/selfPage';
import CollaboratorsList from './components/aboutUs/collaborators/collaboratorsList';
import CollaboratorsPage from './components/aboutUs/collaborators/collaboratorsPage';
import PartnersList from './components/aboutUs/partners/partnersList';
import PartnersPage from './components/aboutUs/partners/partnersPage';
import TeamList from './components/aboutUs/team/teamList';
import TeamPage from './components/aboutUs/team/teamPage';
import programList from './components/program/programList';
import programPage from './components/program/programPage';
import PositionList from './components/position/positionList';
import PositionPage from './components/position/positionPage';
import LivePage from './components/live/livePage';
import MediaList from './components/media/mediaList';

//Routing
import { Router, Route, Switch } from 'react-router-dom';

function MainComponent() {
	return (
		<Route path='/' component={Home}>
			<Route exact path='/home' component={Home} />
			<Route exact path='/aboutUs/self/' component={AboutUsSelfPage} />
			<Route
				exact
				path='/aboutUs/collaborators/'
				component={CollaboratorsList}
			/>
			<Route
				exact
				path='/aboutUs/collaborators/:id'
				component={CollaboratorsPage}
			/>
			<Route exact path='/aboutUs/partners' component={PartnersList} />
			<Route exact path='/aboutUs/partners/:id' component={PartnersPage} />
			<Route exact path='/aboutUs/team' component={TeamList} />
			<Route exact path='/aboutUs/team/:id' component={TeamPage} />
			<Route exact path='/program' component={programList} />
			<Route exact path='/program/:id' component={programPage} />
			<Route exact path='/positions' component={PositionList} />
			<Route exact path='/positions/:id' component={PositionPage} />
			<Route exact path='/live' component={LivePage} />
			<Route exact path='/media' component={MediaList} />
		</Route>
	);
}

function App() {
	let [loggedIn, setLoggedIn] = useState(false);
	// eslint-disable-next-line
	let [username, setUsername] = useStateWithLocalStorage('username');
	let [lang, setlang] = useStateWithLocalStorage('language');
	if (lang === '') setlang('en');
	async function logout() {
		await setCookies('');
		await setUsername('');
		await setLoggedIn(false);
	}

	checkToken();

	async function checkToken() {
		let token = await cookies.get('authorization');
		if (token) {
			API.post('/veryfyLogin', {
				token: token,
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(token);
						setLoggedIn(true);
					} else {
						setLoggedIn(false);
					}
				})
				.catch((res) => {
					console.error(res);
					console.error(`Please don't edit cookies manually`);
					cookies.remove('authorization');
				});
		} else {
			setLoggedIn(false);
			console.log(
				'You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running'
			);
		}
	}

	function login(username, password, role) {
		if (username && password && role) {
			API.post(`/login`, {
				username: username,
				password: password,
				role: 'admin',
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(res.data.token);
						setLoggedIn(true);
						setUsername(res.data.user[0].name);
					}
				})
				.catch((error) => {
					console.error(error);
					setLoggedIn(false);
				});
		} else {
			setLoggedIn(false);
			console.log('There is no user with provided username & password');
		}
	}

	if (loggedIn) {
		return (
			<Language.Provider value={{ lang, setlang }}>
				<isLoggedIn.Provider
					value={{ loggedIn, setLoggedIn, checkToken, logout, login }}
				>
					<Router history={history}>
						<Switch>
							<Menu component={MainComponent} />
						</Switch>
					</Router>
				</isLoggedIn.Provider>
			</Language.Provider>
		);
	} else {
		return (
			<isLoggedIn.Provider
				value={{ loggedIn, setLoggedIn, checkToken, logout, login }}
			>
				<Router history={history}>
					<Switch>
						<Route component={Login} />
					</Switch>
				</Router>
			</isLoggedIn.Provider>
		);
	}
}

export default App;
