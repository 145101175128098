import React, { useState, Fragment } from 'react';
import config from '../../config';
import { DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
// ! upload stuff
import Button from '@material-ui/core/Button';
// ! Delete button with icon
import IconButton from '@material-ui/core/IconButton';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

// ! grid stuff
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// ! Card stuff
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
	image: {
		margin: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	media: {
		height: 190,
		padding: theme.spacing(0.5),
		textAlign: 'center',
	},
	dropzone: {
		overflow: 'hidden',
	},
	grid: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		flexWrap: 'nowrap',
		height: 'auto',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)',
	},
	// eslint-disable-next-line
	['gridList li']: {
		height: 'auto',
	},
	gridTitle: {
		color: theme.palette.secondary.light,
	},
	gridTitleBar: {
		background:
			'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export function SliderComponent(props) {
	const [openDialog, setDialog] = useState(false);
	const classes = useStyles();

	var disabled = false;
	var fileId = '';
	var fileLang = '';
	if (props.file === undefined) {
		disabled = true;
	} else if (props.file) {
		fileId = props.file._id;
		fileLang = props.lang;
	}

	const handleClose = () => {
		setDialog(false);
	};

	const handleSave = (files) => {
		setDialog(false);
		props.uploadFile(files, props.lang);
	};

	const handleOpen = () => {
		setDialog(true);
	};

	const removeFile = (e) => {
		props.removeFile(e.currentTarget.id);
	};

	return (
		<Fragment>
			<Card className={classes.image}>
				<CardContent>
					<CardMedia className={classes.media}>
						<GridList cellHeight={160} className={classes.gridList} cols={2.5}>
							{props &&
								props.files &&
								props.files.map((img, index) => (
									<GridListTile key={index}>
										<img
											src={`${config.routes.API_URL}/${img.path}`}
											alt={img.title}
											id={img._id}
										/>
										<GridListTileBar
											title={img.title}
											classes={{
												root: classes.gridTitleBar,
												title: classes.gridTitle,
											}}
											actionIcon={
												<IconButton
													onClick={removeFile}
													id={img._id}
													aria-label={`star ${img.title}`}
												>
													<DeleteIcon className={classes.gridTitle} />
												</IconButton>
											}
										/>
									</GridListTile>
								))}
						</GridList>
					</CardMedia>
				</CardContent>
				<CardActions>
					<DropzoneDialog
						open={openDialog}
						onSave={handleSave}
						acceptedFiles={props.acceptedFiles}
						showPreviews={true}
						maxFileSize={5000000}
						onClose={handleClose}
						filesLimit={props.filesUploadLimit}
						dropzoneText={props.dropzoneText}
					/>
					<div className={classes.buttonGroup}>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							disabled={!disabled}
							id={fileId}
							lang={fileLang}
							onClick={handleOpen}
						>
							{props.buttonAddText}
						</Button>
					</div>
				</CardActions>
			</Card>
		</Fragment>
	);
}
