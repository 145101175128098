import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { navigationPrimary, navigationSecondary } from './listItems';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAuth } from '../../helpers/auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

import Menue from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflow: 'hidden',
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	collapse: {
		marginLeft: theme.spacing(2),
	},
	selected: {
		backgroundColor: '#222222',
	},
}));

export default function Menu({ component: Component, ...rest }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [openDrawer, setOpenDrawer] = React.useState(true);
	const location = useLocation();
	let [username, setUsername] = useState('');
	let [isBusy, setIsBusy] = useState(true);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleUserMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = () => {
		setOpenDrawer((prevOpen) => !prevOpen);
	};

	const activeRoute = (routeName) => {
		return location.pathname.indexOf(routeName) > -1 ? true : false;
	};

	const { logout } = useAuth();

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	// const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	useEffect(() => {
		(async () => {
			await setUsername(localStorage.getItem('username'));
			await setIsBusy(false);
		})();
	}, [isBusy]);

	if (isBusy) {
		return <LinearProgress />;
	} else {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position='absolute'
					className={clsx(classes.appBar, open && classes.appBarShift)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='open drawer'
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component='h1'
							variant='h6'
							color='inherit'
							noWrap
							className={classes.title}
						>
							Control Panel Perf Festival
						</Typography>
						<Typography component='h2' variant='h6' color='inherit' noWrap>
							{username}
						</Typography>
						<IconButton
							aria-label='more'
							aria-controls='long-menu'
							aria-haspopup='true'
							style={{ color: 'white' }}
							onClick={handleUserMenuOpen}
						>
							<MoreVertIcon />
						</IconButton>
						<Menue
							id='simple-menu'
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={logout}>
								Exit <ExitToAppIcon />
							</MenuItem>
						</Menue>
					</Toolbar>
				</AppBar>
				<Drawer
					variant='permanent'
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List>
						{navigationPrimary.map((obj, index) =>
							obj.collapse ? (
								<Fragment key={index}>
									<ListItem button onClick={handleClick}>
										<ListItemIcon color='primary'>
											{openDrawer != null ? (
												openDrawer ? (
													<ExpandLess />
												) : (
													<ExpandMore />
												)
											) : null}
										</ListItemIcon>
										<ListItemText>
											{obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
										</ListItemText>
									</ListItem>
									<Collapse
										component='li'
										in={openDrawer}
										timeout='auto'
										unmountOnExit
										key={index}
										className={classes.collapse}
									>
										{obj.collapse.map((innerList, ind) => (
											<Link key={ind} to={`/${innerList.route}/`}>
												<ListItem
													button
													selected={activeRoute(innerList.route)}
												>
													<ListItemIcon>{obj.icon}</ListItemIcon>
													<ListItemText>
														{innerList.name.charAt(0).toUpperCase() +
															innerList.name.slice(1)}
													</ListItemText>
												</ListItem>
											</Link>
										))}
									</Collapse>
								</Fragment>
							) : (
								<Link key={index} to={`/${obj.route}/`}>
									<ListItem button selected={activeRoute(obj.route)}>
										<ListItemIcon>{obj.icon}</ListItemIcon>
										<ListItemText>
											<Typography>
												{obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
											</Typography>
										</ListItemText>
									</ListItem>
								</Link>
							)
						)}
					</List>
					<Divider />
					<List>
						{navigationSecondary.map((obj, index) => (
							<Link key={index} to={`/${obj.route}/`}>
								<ListItem button selected={activeRoute(obj.route)}>
									<ListItemIcon>{obj.icon}</ListItemIcon>
									<ListItemText>
										<Typography variant='body2' component='h2'>
											{obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
										</Typography>
									</ListItemText>
								</ListItem>
							</Link>
						))}
					</List>
					<Divider />
					<a href='https://deepmindsystems.com' className='deepmindsystems'>
						<img src={`/Logo.svg`} alt='DeepmindSystem Logo' />
						<p className='dms-name expanded'>DeepMindSystems.com</p>
						<p className='dms-name small'>DMS</p>
					</a>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container className={classes.container}>
						<Component />
					</Container>
				</main>
			</div>
		);
	}
}
