import React, { useEffect, useState } from 'react';
import { ListPage } from '../../ListPage';
import config from '../../../config';
import * as API from '../../../helpers/api';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

export default function CollaboratorsList() {
	let [apiData, setApiData] = useState([]);
	let [columns, setColumns] = useState([]);
	let [isBusy, setBusy] = useState(true);

	useEffect(() => {
		(async () => {
			const rawData = await API.get(config.routes.aboutUs.collaborators);
			setApiData(rawData.data);

			// const categoriesRaw = await API.get(config.routes.category);
			// const categories = categoriesRaw.data;

			// const pdfsRaw = await API.get(config.routes.upload, {
			// 	type: { $regex: '.*pdf.*' },
			// });
			// const pdfs = pdfsRaw.data;

			setColumns([
				{ title: 'Name', field: 'nameEn' },
				{ title: 'Անուն', field: 'nameHy' },
				// {
				// 	title: 'Pdf En',
				// 	field: 'pdfEn',
				// 	render: (rowData) => {
				// 		let result = <ErrorIcon />;
				// 		pdfs.map((pdf) => {
				// 			if (
				// 				pdf.lang === 'en' &&
				// 				pdf.obj_id === rowData._id
				// 			) {
				// 				result = <CloudDoneIcon />;
				// 			}
				// 		});
				// 		return result;
				// 	},
				// },
				// {
				// 	title: 'Pdf Ru',
				// 	field: 'pdfRu',
				// 	render: (rowData) => {
				// 		let result = <ErrorIcon />;
				// 		pdfs.map((pdf) => {
				// 			if (
				// 				pdf.lang === 'ru' &&
				// 				pdf.obj_id === rowData._id
				// 			) {
				// 				result = <CloudDoneIcon />;
				// 			}
				// 		});
				// 		return result;
				// 	},
				// },
				// {
				// 	title: 'Категория',
				// 	field: 'category',
				// 	render: (rowData) => {
				// 		let category = '';
				// 		categories.map((cat) => {
				// 			if (rowData.category_id === cat._id) {
				// 				category = cat.nameRu
				// 					? cat.nameRu
				// 					: cat.nameEn
				// 					? cat.nameEn
				// 					: cat.nameHy
				// 					? cat.nameHy
				// 					: 'нет информации';
				// 			}
				// 		});
				// 		return category;
				// 	},
				// },
			]);
			setBusy(false);
		})();
	}, [isBusy]);

	if (!isBusy) {
		return (
			<ListPage
				apiData={apiData}
				title='Collaborators'
				columns={columns}
				CLICK={true}
				tooltip='Add collaborator'
			/>
		);
	} else {
		return <LinearProgress color='primary' />;
	}
}
