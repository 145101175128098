import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
// For clipboard stuff
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DropzoneArea } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { Divider } from '@material-ui/core';

import { useLang } from '../../helpers/language';
import Pagination from '../../helpers/paginate';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'hidden',
		justifyContent: 'space-between',
	},
	image: {
		maxWidth: 300,
		margin: theme.spacing(1),
	},
	media: {
		height: 200,
	},
	button: {
		margin: theme.spacing(1),
	}
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const PaginationContent = ({
	paginationContent,
	loading,
	lang,
	setOpen,
	removeFile,
}) => {
	let classes = useStyles();

	const copyToPlipboard = async (e) => {
		let url = e.currentTarget.id;
		await navigator.clipboard.writeText(url);
		handleClick();
	};

	const handleClick = () => {
		setOpen(true);
	};

	if (loading) return <LinearProgress color='primary' />;
	return paginationContent.map((pgnContent) => (
		<Card className={classes.image} key={pgnContent._id}>
			<CardActionArea>
				<CardMedia
					className={classes.media}
					image={`${config.routes.API_URL}/${pgnContent.path}`}
				/>
			</CardActionArea>
			<CardActions>
				<ButtonGroup>
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						startIcon={<FileCopyIcon />}
						id={`${config.routes.API_URL}/${pgnContent.path}`}
						onClick={copyToPlipboard}
					>
						Copy Link
					</Button>
					<Button
						variant='contained'
						color='secondary'
						className={classes.button}
						startIcon={<DeleteIcon />}
						id={pgnContent._id}
						onClick={removeFile}
					>
						Remove
					</Button>
				</ButtonGroup>
			</CardActions>
		</Card>
	));
};

export default function MediaList() {
	let classes = useStyles();
	let history = useHistory();
	let { lang } = useLang();
	let id = useParams().id;
	const [isBusy, setBusy] = useState(true);
	const [files, setFiles] = useState('');
	const [open, setOpen] = React.useState(false);
	let [screenWidth] = useState(window.innerWidth.toString());

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [dataPerPage] = useState(screenWidth > 1270 ? 6 : 4);
	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	// Get current posts
	const indexOfLastPost = currentPage * dataPerPage;
	const indexOfFirstPost = indexOfLastPost - dataPerPage;
	const currentData = files.slice(indexOfFirstPost, indexOfLastPost);

	//! components specific functions
	async function removeFile(e) {
		setBusy(true);
		await API.remove(config.routes.upload, e.currentTarget.id);
	}

	async function uploadFile(files, lang) {
		setBusy(true);
		await API.upload(id, files, lang);
		setBusy(false);
	}

	useEffect(() => {
		(async () => {
			const rowData = await API.get(config.routes.upload, {
				type: { $regex: '.*image.*' },
			});
			setFiles(rowData.data);
			setBusy(false);
		})();
	}, [isBusy, history.location.pathname]);

	return (
		<Fragment>
			{isBusy ? (
				<LinearProgress color='primary' />
			) : (
				<Fragment>
					<div className={classes.root}>
						<DropzoneArea
							filesLimit={100}
							dropzoneText={'Drop to upload , up to 10 images'}
							dropzoneClass={classes.media}
							showPreviews={false}
							// showPreviewsInDropzone={false}
							// useChipsForPreview={false}
							onChange={uploadFile}
							// onDelete={handleFeatureImgDelete}
						/>
						<Divider />
						<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
							<Alert onClose={handleClose} severity='info'>
								Link Copied
							</Alert>
						</Snackbar>

						{files !== undefined ? (
							<>
								<PaginationContent
									paginationContent={currentData}
									loading={isBusy}
									lang={lang}
									removeFile={removeFile}
									setOpen={setOpen}
								/>
								<Pagination
									dataPerPage={dataPerPage}
									totalCount={files.length}
									paginate={paginate}
									currentPage={currentPage}
								/>
							</>
						) : (
							<h2 className='text-center w-100 align-self-center'>
								Here are no content to show
							</h2>
						)}
					</div>
				</Fragment>
			)}
		</Fragment>
	);
}
